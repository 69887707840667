import { Box } from '@mui/material';
import * as React from 'react';
import BasePage, { BasePageProps } from './BasePage';

export interface BaseInfoPageProps extends BasePageProps {
}

export const BaseInfoPage: React.FunctionComponent<BaseInfoPageProps> = (props) => {

    return (
        <BasePage {...props}>
            <Box sx={{paddingTop: '2.1875rem', margin: 'auto', maxWidth: '37.25rem', paddingX: {xs: 0, md: 2}}}>
                {props.children}
            </Box>
        </BasePage>
    );
}

export default BaseInfoPage;
