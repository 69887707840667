import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material';

export const ContentMarginBox: React.FunctionComponent<{children?: React.ReactNode, sx?: SxProps<Theme>}> = (props) => {
    const theme = useTheme();
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection:'row',
            ...props.sx
        }}>
            <Box minWidth={theme.minContentMargin}/>
            <Box flexGrow={1} sx={{maxWidth: theme.maxContentWidth, marginX: 'auto'}}>
                {props.children}
            </Box>
            <Box minWidth={theme.minContentMargin}/>
        </Box>
    );
}
