import { Colors, setCommon } from './Common';

export const Dark = setCommon(Colors.white, {
    spacing: 16,
    typography: {
        body1: {
            fontWeight: 500,
            // lineHeight: '1.175rem' didn't work with the grid?
        },
        h1: {
            color: Colors.pilotYellow,
            fontWeight: 700,
            fontSize: '1rem',
            // lineHeight: '1.175rem',
            textTransform: 'uppercase'
        },
        h2: {
            fontWeight: 400,
            fontSize: '2.125rem',
            // lineHeight: '2.497rem'
        },
        h3: {
            fontWeight: 500,
            fontSize: '1.313rem',
            // lineHeight: '1.555rem'
        },
        h4: {
            fontWeight: 600,
            fontSize: '1.313rem',
            // lineHeight: '1.555rem'
        }
    },
    palette: {
        mode: "dark",
        primary: {
            main: Colors.pilotYellow,
            dark: Colors.pilotBrown,
            light: Colors.pilotBrown,
            contrastText: Colors.pilotBlue
        },
        error: {
            main: Colors.red
        },
        success: {
            main: Colors.pilotGreen
        },
        background: {
            paper: Colors.pilotBlue,
            default: Colors.pilotBlue
        },
        action: {
            disabled: Colors.gray3
        },
        divider: Colors.pilotYellow
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(12, 31, 43, 0.9)"
                }
            }
        }
    }
});

