import { ThemeProvider } from '@mui/material/';
import Box from '@mui/material/Box';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { ContentMarginBox, PageStateProxy, PageStateProxyProps } from '../components';
import * as Themes from '../themes';

export interface BasePageProps extends PageStateProxyProps {
    useDarkTheme?: boolean,
    stepWizardProps: StepWizardChildProps;
    children?: React.ReactNode
}

export interface StepWizardPageProps {
    stepName?: string;
    nextPage: () => Promise<void>;
    previousPage: () => Promise<void>;
}

const BasePage: React.FunctionComponent<BasePageProps> = (props) => {
    const rootRef = React.useRef<HTMLElement>();

    React.useEffect(() => {
        const ref = rootRef.current;
        if (ref && ref.parentElement) {
            if (props.stepWizardProps.isActive) {
                ref.style.display = "block";
            }
            else {
                const styles = window.getComputedStyle(ref.parentElement);
                const animationDuration = parseFloat(styles.getPropertyValue('animation-duration'));

                if (animationDuration <= 0) {
                    ref.style.display = "none";
                }
                else {
                    const timeout = setTimeout(() => {
                        ref.style.display = "none";
                    }, animationDuration * 1000);
                    return () => clearTimeout(timeout);
                }
            }
        }
    }, [props.stepWizardProps.isActive]);

    return (
        <ThemeProvider theme={props.useDarkTheme ? Themes.Dark : Themes.Light}>
            <Box bgcolor="background.default" ref={rootRef} sx={{minHeight: '100vh'}}>
                <ContentMarginBox sx={{paddingTop: '4.875rem', paddingBottom: props.showNavigationButtons ? '12.75rem' : 0}}>
                    {props.children}
                </ContentMarginBox>
                <PageStateProxy {...props} />
            </Box>
        </ThemeProvider>
    );
}

BasePage.defaultProps = {
    useDarkTheme: false,
    canNavigateBack: true,
    canNavigateForward: true,
    showCostEstimate: false,
    showNavigationButtons: false,
    showLogo: false,
    showProgress: false
};

export default BasePage;
