import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createMemoryHistory } from "history";
const { NODE_ENV } = process.env;

const productionConnectionString = 'InstrumentationKey=2e6c8489-2a27-45e9-8c01-dd3951e6428a;IngestionEndpoint=https://westus3-1.in.applicationinsights.azure.com/';
 // Set this to something else if you don't want to log to the production application insights
const testConnectionString = productionConnectionString;

const browserHistory = createMemoryHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        connectionString: NODE_ENV === 'production' ? productionConnectionString : testConnectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();