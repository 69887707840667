import * as React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme, useTheme } from '@mui/material';

// Use as example of a reusable component
export const BorderedBox: React.FunctionComponent<{children?: React.ReactNode, sx?: SxProps<Theme>}> = (props) => {
    const theme = useTheme();
    return (
        <Box
        sx={{
            border: 1,
            borderColor: theme.palette.divider,
            borderRadius: '4px',
            height: '16.125rem', // Place holder. Needs to be responsive.
            ...props.sx
        }}
        >
        {props.children}
        </Box>
    );
}
