import { Box, Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { SimSteps } from '../models';
import { BaseInfoPage } from './BaseInfoPage';
import { StepWizardPageProps } from './BasePage';

export const IntroPage: React.FunctionComponent<StepWizardPageProps> = (props) => {

    return (
        <BaseInfoPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            useDarkTheme={true}
            userUpdates={{}}>
        <Box>
            <Grid container rowSpacing={2}>
                <Grid xs={12}>
                    <Typography variant='h1'>
                        Introduction
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Box sx={{ display: 'flex'}}>
                        <img src="/images/waruc_logo_notext.jpg" alt="WA RUC Logo" style={{ width: '60px', marginRight: '10px' }} />
                        <Typography variant='h2' sx={{alignSelf:'center'}}>
                            Get Ready for the Simulation
                        </Typography>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Typography>
                        Imagine you just received a notification from the State of Washington stating you need to report and pay your annual road usage charge. This simulation will guide you through the process of reporting and paying this road usage charge.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography>
                        This experience only simulates the reporting and payment experience. 
                    </Typography>
                    <Typography sx={{fontWeight:'bold'}}>
                        No real money will be exchanged.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography>
                        The simulation uses a road usage charge of 2.4 cents per mile driven. This is the amount the average vehicle currently pays through the state's 49.4 cent-per-gallon gas tax.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography>
                        After you finish the simulation, you'll be asked to tell us about your experience to earn your thank-you reward.
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Divider />
                </Grid>
                <Grid xs={12}>
                    <Button sx={{float: 'right'}} variant="contained" onClick={props.nextPage}>Enter the Simulation</Button>
                </Grid>
            </Grid>
        </Box>
        </BaseInfoPage>

    );
}

IntroPage.defaultProps = {
    stepName: SimSteps.Intro.name
};
