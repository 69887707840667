import { Box, ToggleButton, Typography } from '@mui/material';
import * as React from 'react';
import _ from 'lodash';
import { BaseToggleButtonGroup, BaseToggleButtonGroupProps } from '.';
import { Colors } from '../themes/Common';

export interface IHeaderBodyValue {
    value: string,
    header?: string,
    body?: string,
    bodySub?: string,
}

export interface HeaderBodyToggleButtonGroupProps extends Omit<BaseToggleButtonGroupProps, 'children'> {
    values: IHeaderBodyValue[] | {[index: string] : IHeaderBodyValue},
}

export const HeaderBodyToggleButtonGroup: React.FunctionComponent<HeaderBodyToggleButtonGroupProps> = (props) => {
    return (
        <BaseToggleButtonGroup {...props}>
            {_.map(props.values, (item: IHeaderBodyValue) =>
                <ToggleButton key={item.value} value={item.value}>
                    <Box height="100%" width="100%" textAlign='left'>
                        <Typography variant='h3' sx={{paddingBottom: '0.75rem'}}>
                            {item.header || item.value}
                        </Typography>
                        <Box lineHeight='1.1019rem' paddingTop='0.125rem'>
                            <Typography display="inline" marginTop="5px" fontSize='0.9375rem'>
                                {item.body}
                            </Typography>
                            <Typography display="inline" fontStyle='italic' color={Colors.gray4} fontSize='0.9375rem'>
                                {item.bodySub ? ' ' + item.bodySub : undefined}
                            </Typography>
                        </Box>
                    </Box>
                </ToggleButton>
            )}
        </BaseToggleButtonGroup>
    );
}

HeaderBodyToggleButtonGroup.defaultProps = {
    requireValue: true
}
