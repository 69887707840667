import { Box, Checkbox, Divider, FormControlLabel, ToggleButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { BaseToggleButtonGroup, BasicModal, PilotModal } from '../components';
import { UserContext } from '../contexts';
import { OptInMessage, ReportingOptions, SimSteps } from '../models';
import { Colors } from '../themes/Common';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const ReportingOptionPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {user} = React.useContext(UserContext);
    const [mi_report, setMi_report] = React.useState(ReportingOptions.Self);
    const [showNotAvailableDialog, setShowNotAvailableDialog] = React.useState(false);
    const [showContinueDialog, setShowContinueDialog] = React.useState(false);
    const [showFollowupDialog, setShowFollowupDialog] = React.useState(false);
    const [follow_opt, setFollow_opt] = React.useState<boolean | undefined>(undefined);

    const options = {
        Self: {value: ReportingOptions.Self, body: 'Check your odometer and manually enter your mileage.', price: 'Free', features: [
            'No device or app needed',
            'Manually report mileage',
            'Location data not shared with the state',
            'Exempt miles manually (receipts required)',
        ]},
        Telematics: {value: ReportingOptions.Telematics, body: 'Share mileage through your vehicle’s manufacturer.', price: '$3/month for enhanced option', features: [
            'Enrollment with automaker',
            'Automatically reports mileage',
            'Location data not shared with the state',
            'Exempt miles automatically deducted',
        ]},
        Device: {value: ReportingOptions.Device, body: 'Install an OBD-II monitor to share your mileage.', price: '$5/month for enhanced option', features: [
            'Plug-in device provided',
            'Automatically reports mileage',
            'Location data not shared with the state',
            'Exempt miles automatically deducted (optional)',
        ]},
        Mobile: {value: ReportingOptions.Mobile, body: 'Install a smartphone app to log and report mileage.', price: '$1/month for enhanced option', features: [
            'Smartphone app required',
            'Automatically reports mileage',
            'Location data not shared with the state',
            'Exempt miles automatically deducted',
        ]},
    }

    React.useEffect(() => {
        if (user) {
            setMi_report(user.mi_report || ReportingOptions.Self);
            setFollow_opt(user.follow_opt || undefined);
        }
    }, [user]);

    const handleAcceptClick = async () => {
        if (mi_report === ReportingOptions.Self) {
            if (user && user?.effectiveExemptMiles() > 200) {
                setShowContinueDialog(true);
                return false;
            }
        }
        else if (mi_report === ReportingOptions.Telematics && user && user.telematics && user.recruit !== 'IPSOS' && follow_opt === undefined) {
            setFollow_opt(true);
            setShowFollowupDialog(true);
            return false;
        }
        else {
            setShowNotAvailableDialog(true);
            return false;
        }

        return true;
    }

    const continueDialogOnClose = async (_e: object, reason: string) => {
        setShowContinueDialog(false);
        if (reason === "accept") {
            await props.nextPage();
        }
    };

    const notAvailableDialogOnClose = async (_e: object, reason: string) => {
        setShowNotAvailableDialog(false);
        if (reason === "accept") {
            await props.nextPage();
        }
    };

    const followupDialogOnClose = async (_e: object, reason: string) => {
        setShowFollowupDialog(false);
        if (reason === "accept") {
            await props.nextPage();
        }
        else {
            setFollow_opt(undefined);
        }
    };

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            acceptOnClick={handleAcceptClick}
            userUpdates={{
                mi_report,
                follow_opt
            }}
            title="How would you like to report your mileage for next year?"
            learnMoreLink="Learn more about reporting options"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h2">
                            A range of mileage reporting options offers choice and convenience.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            Privacy of your data is of paramount importance. Information you elect to share remains secure, confidential, and will not be shared for any reason without your express permission pursuant to state law.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h3">
                            Several options are available:
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>Self-Reporting</b> has you type in your odometer reading and provide a photo of your odometer.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{ fontWeight: 600 }}>Vehicle Telematics</b> uses technology built into your vehicle to automatically report miles driven with no further action required on your part. Your data will only be used to report your road usage charge. No location data will be shared with the state.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{ fontWeight: 600 }}>Installed Device</b> uses a small device that plugs into your vehicle beneath the dashboard. The device counts and automatically transmits miles driven, with an option to use GPS if you want to exclude miles driven out of state and/or on private roads. No location data will be shared with the state.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{ fontWeight: 600 }}>Mobile App</b> relies on an app that counts and automatically transmits miles driven, with an option to use GPS if you want to exclude miles driven out of state and/or on private roads. Your data will only be used to report your road usage charge. No location data will be shared with the state.
                        </Typography>
                    </Grid>
                </Grid>
            }>
            <BaseToggleButtonGroup
                value={mi_report}
                onChange={setMi_report}>
                {_.map(options, (item) =>
                <ToggleButton key={item.value} value={item.value} sx={{
                    position: 'relative',
                    marginTop: user?.telematics && item.value === ReportingOptions.Telematics && isSmall ? '1.9375rem' : 0
                    }}>
                    <Box sx={{
                        backgroundColor: Colors.green,
                        color: 'background.default',
                        textAlign: 'center',
                        width: '8.8125rem',
                        left: 0,
                        top: 0,
                        transform: 'translate(0, -100%)',
                        position: 'absolute',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        display: user?.telematics && item.value === ReportingOptions.Telematics ? 'block' : 'none'}}>
                        You’re Eligible
                    </Box>
                    <Box height="100%" width="100%" display='flex' flexDirection='column'>
                        <Typography variant='h3' textAlign="left">
                            {item.value}
                        </Typography>
                        <Typography marginTop="5px" textAlign="left">
                            {item.body}
                        </Typography>
                        <Box flexGrow={1} sx={{
                            backgroundColor: 'background.default',
                            marginX: '-1.25rem',
                            marginBottom: '-1.25rem',
                            paddingBottom: '0.35rem',
                            marginTop: '0.625rem',
                            borderBottomLeftRadius: 4,
                            borderBottomRightRadius: 4
                        }}>
                            <Divider/>
                            <Box marginX='1.125rem'>
                                {_.map(item.features, (feature) =>
                                    <Box>
                                        <Typography variant='h4' marginY="0.625rem" textAlign="left">
                                            {feature}
                                        </Typography>
                                        <Divider/>
                                    </Box>
                                )}
                                <Typography variant="h3" marginY="0.625rem" textAlign="left">
                                    {user?.telematics && item.value === ReportingOptions.Telematics ? 'Free' : item.price}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </ToggleButton>
                )}
            </BaseToggleButtonGroup>
            <BasicModal
                open={showContinueDialog}
                onClose={continueDialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Confirm"
                title="If you drive out-of-state and/or on private roads more than 200 miles per year, you will need to submit proof at the end of the year."
                >
            </BasicModal>
            <PilotModal
                open={showNotAvailableDialog}
                onClose={notAvailableDialogOnClose}
                acceptLabel="Continue"
                title="Thank you for your selection.">
                <Typography>
                    The simulation does not feature further interactions for your selection. Please continue.
                </Typography>
            </PilotModal>
            <PilotModal
                open={showFollowupDialog}
                onClose={followupDialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Continue"
                title="Thank you for choosing vehicle telematics to report your mileage.">
                <Box>
                    <Typography marginBottom="1rem">
                        {OptInMessage.Intro}
                    </Typography>
                    <Typography marginBottom="1rem">
                        {OptInMessage.Body}
                    </Typography>
                    <FormControlLabel label="Yes, I want to participate in further research." control={
                        <Checkbox checked={!!follow_opt} onChange={(e) => setFollow_opt(e.target.checked)}/>
                    }/>
                </Box>
            </PilotModal>
        </BaseInputPage>
    );
}

ReportingOptionPage.defaultProps = {
    stepName: SimSteps.ReportingOption.name
};
