import { Box, Button, Checkbox, FormControlLabel, TextField, ToggleButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { BasicModal, BorderedBox, PilotModal } from '../components';
import { UserContext } from '../contexts';
import { SimSteps, UploadPicMethods } from '../models';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

const boxWidth = "16.125rem";

const PickBoxStyles = {
    padding: '1.25rem',
    height: '10.938rem'
  };

const PickBoxTitleStyles = {
    paddingBottom: '0.75rem'
}

const PickBoxBodyStyles = {
    paddingBottom: '3.875rem'
}

export const MileageVerificationPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [odo_pic, setOdo_pic] = React.useState(UploadPicMethods.None);
    const [showPilotDialog, setShowPilotDialog] = React.useState(false);
    const [showDeclineDialog, setShowDeclineDialog] = React.useState(false);

    React.useEffect(() => {
        setOdo_pic((user && user.odo_pic) ? user.odo_pic : UploadPicMethods.None);
    }, [user]);

    const pilotDialogOnClose = async (event: object, reason: string) => {
        setShowPilotDialog(false);
        if (reason === "accept") {
            props.nextPage();
        }
    };

    const declineDialogOnClose = (event: object, reason: string) => {
        if (reason !== "accept") {
            setOdo_pic(UploadPicMethods.None);
        }

        setShowDeclineDialog(false);
    };

    const handleUploadMethodChanged = (method: string) => {
        setOdo_pic(method);
        if (method === UploadPicMethods.Decline) {
            if (method !== odo_pic) {
                setShowDeclineDialog(true);
            }
        }
        else if (method !== UploadPicMethods.None) {
            setShowPilotDialog(true);
        }
    }

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            canNavigateForward={odo_pic !== UploadPicMethods.None}
            userUpdates={{
                odo_pic
            }}
            title="Add a photo of your odometer for verification and reduce the chance of audit."
            learnMoreLink="Learn more about mileage verification"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h2">
                            Uploading a photo is a simple way to verify your odometer reading.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            Providing accurate odometer readings ensures all drivers pay their fair share for their road usage. Providing inaccurate readings is unlawful and may subject you to fines and penalties.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h3">
                            You have several options:
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>Upload Now</b> enables you to provide a photo of your odometer during this reporting  process. Simply take a photo and upload it from your device.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>Upload Later Via Text</b> allows you to submit your photo later. We will send you a text message with a link to take and upload your photo within 48 hours of completing your mileage reporting.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>Upload Later Via QR Code</b> allows you to submit your photo later. Scanning the QR code on the screen  opens a link to take and upload your photo within 48 hours of completing your mileage reporting.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            You may decline submitting a photo of your odometer. Doing so increases your risk of audit.
                        </Typography>
                    </Grid>
                </Grid>
            }>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gridGap: "1rem",
                alignItems: "end",
                marginTop: "-1.5rem",
                marginBottom: "1.25rem" }}>
                <Box width={boxWidth}>
                    <BorderedBox>
                        <Box style={PickBoxStyles}>
                            <Typography variant="h3" style={PickBoxTitleStyles}>
                                Upload Now
                            </Typography>
                            <Typography style={PickBoxBodyStyles}>
                                Choose a photo from this device.
                            </Typography>
                                <Button variant="outlined" onClick={() => handleUploadMethodChanged(UploadPicMethods.Upload)}>Choose Photo</Button>
                        </Box>
                    </BorderedBox>
                </Box>
                <Box width={boxWidth}>
                    <Box sx={{
                        backgroundColor: 'action.disabled',
                        textAlign: 'center',
                        width: '11.063rem',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        marginLeft: '0.1875rem'
                        }}>
                            Upload Anytime
                    </Box>
                    <BorderedBox>
                        <Box style={PickBoxStyles}>
                            <Typography variant="h3" style={PickBoxTitleStyles}>
                                Send Via Text
                            </Typography>
                            <Typography style={PickBoxBodyStyles}>
                                Enter your mobile number to receive a link to upload.
                            </Typography>
                            <TextField variant='outlined' value="(000) 000-0000" onClick={() => handleUploadMethodChanged(UploadPicMethods.Text)} inputProps={{readOnly: true}} sx={{ fontSize: '0.1rem', maxWidth: '9.09rem', paddingRight: '0.5rem' }} InputProps={{style: {fontSize: '0.925rem', height: '2.5rem'}}}/>
                            <Button variant="outlined" onClick={() => handleUploadMethodChanged(UploadPicMethods.Text)} sx={{minWidth: '1rem'}}>Send</Button>
                        </Box>
                    </BorderedBox>
                </Box>
                <Box width={boxWidth}>
                    <Box sx={{
                        backgroundColor: 'action.disabled',
                        textAlign: 'center',
                        width: '11.063rem',
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        marginLeft: '0.1875rem'
                        }}>
                            Upload Anytime
                    </Box>
                    <BorderedBox>
                        <Box style={PickBoxStyles}>
                            <Typography variant="h3" style={PickBoxTitleStyles}>
                                Scan QR Code
                            </Typography>
                            <Typography style={PickBoxBodyStyles}>
                                Scan the code to open a link to upload from your phone.
                            </Typography>
                            <Button variant="outlined" onClick={() => handleUploadMethodChanged(UploadPicMethods.QrCode)}>Generate QR Code</Button>
                        </Box>
                    </BorderedBox>
                </Box>
                <Box width={boxWidth}>
                    <BorderedBox>
                        <Box style={PickBoxStyles}>
                        <Typography variant="h3" style={PickBoxTitleStyles}>
                            Decline Submission
                        </Typography>
                        <Typography sx={{paddingBottom: '2.4rem'}}>
                            Decline to submit a photo and increase your risk of audit.
                        </Typography>
                        <ToggleButton value='' selected={odo_pic === UploadPicMethods.Decline} sx={{height: '2.5rem', width: '8.25rem', borderRadius: '2px', boxShadow: 'unset'}} onClick={(e) => handleUploadMethodChanged(odo_pic !== UploadPicMethods.Decline ? UploadPicMethods.Decline : UploadPicMethods.None)}>
                            <FormControlLabel label="Decline" sx={{pointerEvents: 'none'}} control={
                                <Checkbox checked={odo_pic === UploadPicMethods.Decline}/>
                            }/>
                        </ToggleButton>
                        </Box>
                    </BorderedBox>
                </Box>
            </Box>
            <PilotModal
                open={showPilotDialog}
                onClose={pilotDialogOnClose}
                acceptLabel="Continue"
                title="Thank you for your selection.">
                <Typography>
                    The simulation does not feature further  interactions for your selection. Please continue.
                </Typography>
            </PilotModal>
            <BasicModal
                open={showDeclineDialog}
                onClose={declineDialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Confirm"
                title="Are you sure you want to decline?"
                >
                <Typography>
                    Declining to submit an odometer verification photo will be recorded and will increase your chance of being audited in the future.
                </Typography>
            </BasicModal>
        </BaseInputPage>
    );
}

MileageVerificationPage.defaultProps = {
    stepName: SimSteps.MileageVerification.name
};
