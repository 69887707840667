import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { StepWizardChildProps } from 'react-step-wizard';
import { HeaderBodyToggleButtonGroup, PilotModal } from '../components';
import { UserContext } from '../contexts';
import { Exemptions, OptInMessage, SimSteps } from '../models';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const ExemptionPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [exempt, setExempt] = React.useState('None');
    const [exempt_mi, setExempt_mi] = React.useState<number | undefined>(undefined);
    const [showExemptionDialog, setShowExemptionDialog] = React.useState(false);
    const [showFollowupDialog, setShowFollowupDialog] = React.useState(false);
    const [follow_opt, setFollow_opt] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        setExempt((user && user.exempt) ? user.exempt : Exemptions.None.value);
        setExempt_mi((user && user.exempt_mi) ? user.exempt_mi : undefined);
        setFollow_opt(user?.follow_opt || undefined);
    }, [user]);

    const dialogOnClose = async (_e: object, reason: string) => {
        setShowExemptionDialog(false);
        if (reason === "accept") {
            if (user?.recruit !== 'IPSOS' && follow_opt === undefined) {
                setFollow_opt(true);
                setShowFollowupDialog(true);
            }
            else {
                setFollow_opt(false)
                await props.nextPage();
            }
        }
        else {
            setExempt_mi(undefined);
        }
    };

    const followupDialogOnClose = async (_e: object, reason: string) => {
        setShowFollowupDialog(false);
        if (reason === "accept") {
            await props.nextPage();
        }
        else {
            setFollow_opt(undefined);
        }
    };

    const handleAcceptClick = async () => {
        if (exempt === Exemptions.MoreThan200.value) {
            setShowExemptionDialog(true);
            return false;
        }

        return true;
    }

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            acceptOnClick={handleAcceptClick}
            userUpdates={{
                exempt,
                exempt_mi,
                follow_opt
            }}
            title="To claim exemptions for last year, about how many miles did you drive out-of-state or on private roads?"
            learnMoreLink="Learn more about exemptions"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h2">
                        Mileage Exemptions allow drivers to avoid paying for miles driven out of state or on private roadways.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h3">
                            Several options are available:
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>None</b> is for drivers who did not take their vehicle out of state or on private roadways during the previous 12 months.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>Fewer than 200 miles</b> is for drivers who drove their vehicle a small number of miles out of state or on private roadways during the previous 12 months. Selecting this option does not require keeping any evidence of travel. If you drove more than 200 exempt miles last year but do not want to keep evidence and risk audit, you may select this option.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>More than 200 miles</b> is for drivers who drove their vehicle a significant number of miles out of state or on private roadways during the previous 12 months. You must keep records of your exempt miles driven, and the state may require you to share evidence. Failure to provide evidence when requested may result in charges for past claims of exempt miles plus fines and penalties.
                        </Typography>
                    </Grid>
                </Grid>
            }>
            <HeaderBodyToggleButtonGroup
                value={exempt}
                values={Exemptions}
                onChange={setExempt}
            />
            <PilotModal
                open={showExemptionDialog}
                onClose={dialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Submit"
                title="A real system will require proof of exempt miles, but...">
                <Box>
                    <Typography marginBottom="2rem">
                        For now, you only need to provide the number of exempt miles you want to claim for the past 12 months.
                    </Typography>
                    <Typography variant="caption">
                        Exempt Mileage
                    </Typography>
                    <Box height="0.5rem"></Box>
                    <NumberFormat
                        thousandSeparator=','
                        customInput={TextField}
                        onValueChange={(values) => setExempt_mi(parseInt(values.value))}
                        value={exempt_mi || ''}
                        fullWidth={true}
                    />
                </Box>
            </PilotModal>
            <PilotModal
                open={showFollowupDialog}
                onClose={followupDialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Continue"
                title="Thanks for claiming exemptions.">
                <Box>
                    <Typography marginBottom="1rem">
                        {OptInMessage.Intro}
                    </Typography>
                    <Typography marginBottom="1rem">
                    {OptInMessage.Body}
                    </Typography>
                    <FormControlLabel label="Yes, I want to participate in further research." control={
                        <Checkbox checked={!!follow_opt} onChange={(e) => setFollow_opt(e.target.checked)}/>
                    }/>
                </Box>
            </PilotModal>
        </BaseInputPage>
    );
}

ExemptionPage.defaultProps = {
    stepName: SimSteps.Exemption.name
};
