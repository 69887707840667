import * as React from 'react';
import Box from '@mui/material/Box';
import { Drawer, useTheme, hexToRgb } from '@mui/material';
import { CloseIcon, SlideUpIcon } from '../icons';
import { ShowSummaryContext } from '../contexts';

export interface LearnMoreDrawerProps {
    open: boolean,
    summaryShown: boolean,
    children?: React.ReactNode,
    onClose: (() => void)
}

export const LearnMoreDrawer: React.FunctionComponent<LearnMoreDrawerProps> = (props) => {
    const theme = useTheme();
    const {panelWidth} = React.useContext(ShowSummaryContext);

    return (
        <Drawer
            variant='persistent'
            open={props.open}
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: 'transparent',
                    height: '100vh',
                    display: 'flex',
                    flexFlow: 'column',
                    paddingRight: props.summaryShown ? panelWidth + 'px' : '0'
                }
            }}
            anchor='top'
            >
            <Box height='3.533rem' width='100%' bgcolor={hexToRgb(theme.palette.primary.main + 'E6') } sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                <CloseIcon htmlColor={theme.palette.background.default} onClick={() => props.onClose()} sx={{cursor: 'pointer', fontSize: '1.25rem', marginRight: '1.0625rem'}}/>
            </Box>
            <Box flexGrow={1} bgcolor='background.paper'>
                <Box sx={{ paddingTop: 2, margin: 'auto', maxWidth: '37.25rem', paddingX: 2 }}>
                    {props.children}
                </Box>
            </Box>
            <SlideUpIcon onClick={() => props.onClose()} sx={{cursor: 'pointer', position: 'fixed', right: props.summaryShown ? 'calc(2.5rem + ' + panelWidth + 'px)' : '2.5rem', bottom: '2.5rem', fontSize: '3rem'}}/>
        </Drawer>
    );
}

export default LearnMoreDrawer;
