import { Box, Divider, Link, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { ShowLearnMoreContext, UserContext } from '../contexts';
import * as Globals from '../Globals';
import { PaymentPlans, SimSteps } from '../models';
import * as Themes from '../themes';
import { Colors } from '../themes/Common';
import { BaseInputPage } from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

const CenteredBottomLineBoxStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.938rem',
    borderBottom: '1px solid #CBD1C9',
};

const CenteredBoxStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.938rem',
};

const dollars = new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: 'currency',
});

export const ReviewPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [theme] = React.useState(Themes.Light);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {setShowLearnMore} = React.useContext(ShowLearnMoreContext);

    const isQuarterly = user?.pay_opt === PaymentPlans.FourPayments.value;
    const stepWizard = (props as unknown as StepWizardChildProps);
    const paymentPlanAmount = (user && user?.totalDue && user?.totalDue() / 4) || 0;

    return (
        <BaseInputPage
            stepWizardProps={stepWizard}
            userUpdates={{
                gas_paid: user && user?.estimatedGasFee ? parseFloat(user?.estimatedGasFee().toFixed(2)) : 0,
                ruc_paid: user && user?.totalDue ? parseFloat(user?.totalDue().toFixed(2)) : 0,
            }}
            nextButtonLabel="Confirm"
            showCostEstimate={false}
            title="Does everything look correct?"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h2">
                            How does the gas tax work with a road use charge?
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            Many drivers already contribute to road use fees when they buy gas. Each gallon of fuel is taxed to collect revenue for road maintenance projects. As the number of electric vehicles grows we need to support a more inclusive way of contributing to road maintenance.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            <b style={{fontWeight: 600}}>EV owners and the gas tax</b>  RUC will replace the current vehicle registration surcharge paid ($225/year for EV and PHEV, $75/year). Hybrid and PHEV cars that use gas will still pay gas tax.
                        </Typography>
                    </Grid>
                </Grid>
            }>
            <Grid container sx={{
                marginTop: "-1.5rem", display: 'flex', justifyContent: 'space-between'}}>
                <Grid xs={12} md={45} sx={{maxWidth: '19.188rem'}} >
                    <Grid xs={12}>
                        <Box style={CenteredBoxStyles}>
                            <Typography variant='h3'>
                                Your Vehicle
                            </Typography>
                        </Box>
                        <Divider color={Colors.blue3}></Divider>
                    </Grid>
                    <Grid xs={12}>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Make</Typography>
                            {user?.mpg_override ? 'Self-entered' : user?.vehmake || 'Unknown'}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Model</Typography>
                            {user?.mpg_override ? 'Self-entered' : user?.vehmodel || 'Unknown'}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Year</Typography>
                            {user?.mpg_override ? 'Self-entered' : user?.vehyear || 'Unknown'}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>License</Typography>
                            {user?.license || 'Unknown'}
                        </Box>
                        <Box style={CenteredBoxStyles}>
                            <Typography>Average MPG</Typography>
                            {(user?.mpg || '--') + ' mi.'}
                        </Box>
                    </Grid>
                    <Grid xs={12} sx={{paddingTop: '1.719rem'}}>
                        <Box style={CenteredBoxStyles}>
                            <Typography variant='h3'>
                                Road Use Plan
                            </Typography>
                        </Box>
                        <Divider color={Colors.blue3}></Divider>

                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.MileageReporting.name)}>Current Odometer</Link>
                            {user?.odo && (user?.odo + ' mi.')}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.MileageVerification.name)}>Odometer Photo</Link>
                            {
                            user?.odo_pic === 'Decline Submission' ? 'Declined'
                                : !!user?.odo_pic ? 'Uploaded' : ''
                            }
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.Exemption.name)}>Exemptions</Link>
                            {user && user?.effectiveExemptMiles && (user?.effectiveExemptMiles() + ' mi.')}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.ReportingOption.name)}>Reporting</Link>
                            {user && user?.mi_report && user?.mi_report}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.Discounts.name)}>Income-Based Discounts</Link>
                            {(user && user?.hasDiscount && user?.hasDiscount()) ? 'Yes' : 'None' }
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.PaymentPlan.name)}>Payment Plan</Link>
                            {user && user?.pay_opt && user?.pay_opt}
                        </Box>
                        <Box style={CenteredBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => stepWizard.goToNamedStep(SimSteps.PaymentMethod.name)}>Payment Type</Link>
                            {user && user?.pay_method && user?.pay_method}
                        </Box>
                    </Grid>
                </Grid>
                <Grid xs={12} md={8} sx={{
                    paddingLeft: isSmall ? '0' : '1rem',
                    paddingTop: isSmall ? '1.719rem' : '0'
                    }}>
                    <Grid xs={12}>
                        <Typography variant='h3' style={CenteredBoxStyles}>
                            Road Use Charge
                        </Typography>
                        <Divider color={Colors.blue3}></Divider>
                    </Grid>
                    <Grid xs={12}>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Chargeable Miles</Typography>
                            {/* {(user?.mi_est || '--') + ' mi.'} */}
                            {((user?.chargeableMiles ? user.chargeableMiles() : 0) || '--') + ' mi.'}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Charge-Per-Mile</Typography>
                            ${parseFloat(Globals.dollarsPerMile.toFixed(3))}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Income-Based Discount</Typography>
                            {dollars.format(user && user.discountAmount ? user.discountAmount() : 0)}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography><b>Subtotal</b></Typography>
                            <b>{dollars.format(user && user?.subTotal ? user?.subTotal() : 0)}</b>
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Link sx={{cursor: 'pointer'}} onClick={() => setShowLearnMore(true)}>Estimated Gas Taxes Paid</Link>
                            {dollars.format(user && user?.estimatedGasFee ? user?.estimatedGasFee() : 0)}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Transaction Fee (3%)</Typography>
                            {dollars.format(user && user?.transactionFee ? user?.transactionFee() : 0)}
                        </Box>
                        <Box style={CenteredBoxStyles}>
                            <Typography><b>Total</b></Typography>
                            <b>{dollars.format(user && user?.totalDue ? user?.totalDue() : 0)}</b>
                        </Box>
                        <Box style={CenteredBoxStyles} sx={{
                            bgcolor: Colors.gray2,
                            minHeight: '6rem',
                            padding: '1.25rem',
                            marginX: '-1.25rem'
                            }}>
                            <Typography><b>Today's Charge</b></Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                                <Typography variant="h6" sx={{paddingRight: '1.063rem', color: Colors.blue3}}>$</Typography>
                                <Typography variant="h5">{isQuarterly ? paymentPlanAmount.toFixed(2) : user && user?.totalDue && user?.totalDue().toFixed(2)}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    { user && (user?.totalDue().toFixed(2) !== "0.00") && isQuarterly && (
                    <Grid xs={12} sx={{paddingTop: '1.719rem'}}>
                        <Box style={CenteredBoxStyles}>
                            <Typography variant='h3'>
                                Remaining Payments
                            </Typography>
                        </Box>
                        <Divider color={Colors.blue3}></Divider>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Payment 2</Typography>
                            {dollars.format(paymentPlanAmount)}
                        </Box>
                        <Box style={CenteredBottomLineBoxStyles}>
                            <Typography>Payment 3</Typography>
                            {dollars.format(paymentPlanAmount)}
                        </Box>
                        <Box style={CenteredBoxStyles}>
                            <Typography>Payment 4</Typography>
                            {dollars.format(paymentPlanAmount)}
                        </Box>
                    </Grid>
                    )}
                </Grid>
            </Grid>
        </BaseInputPage>
    );
}

ReviewPage.defaultProps = {
    stepName: SimSteps.Review.name
};
