import * as React from 'react';
import BasicModal, { BasicModalProps } from './BasicModal';

export interface PilotModalProps extends BasicModalProps {
}

export const PilotModal: React.FunctionComponent<PilotModalProps> = (props) => {
    return (
        <BasicModal {...props} useDarkTheme={true} header={
            <img src="/images/pilot.svg" alt="Pilot" style={{margin: "0px auto 16px", display: "block"}}/>
        }>
            {props.children}
        </BasicModal>
    );
}

export default PilotModal;
