import { createSvgIcon } from '@mui/material';

export const SlideUpIcon = createSvgIcon(
<svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_0_1)">
<circle cx="24" cy="24" r="24" fill="#1A78BB"/>
</g>
<path d="M20.3594 27.8984L23.25 25.0469V32C23.25 32.7031 23.7969 33.25 24.5 33.25C25.1641 33.25 25.75 32.7031 25.75 32V25.0469L28.6016 27.8984C28.8359 28.1328 29.1484 28.25 29.5 28.25C29.8125 28.25 30.125 28.1328 30.3594 27.8984C30.8672 27.4297 30.8672 26.6094 30.3594 26.1406L25.3594 21.1406C24.8906 20.6328 24.0703 20.6328 23.6016 21.1406L18.6016 26.1406C18.0938 26.6094 18.0938 27.4297 18.6016 27.8984C19.0703 28.4062 19.8906 28.4062 20.3594 27.8984ZM18.25 18.25H30.75C31.4141 18.25 32 17.7031 32 17C32 16.3359 31.4141 15.75 30.75 15.75H18.25C17.5469 15.75 17 16.3359 17 17C17 17.7031 17.5469 18.25 18.25 18.25Z" fill="white"/>
<defs>
<filter id="filter0_d_0_1" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="2" dy="2"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
</defs>
</svg>
    ,
    "SlideUpIcon"
);
