import { Box, Button, Divider, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import X2JS from 'x2js';
import { UserContext } from '../contexts';
import { SimSteps } from '../models';
import * as Server from '../server';
import BaseInfoPage from './BaseInfoPage';
import { StepWizardPageProps } from './BasePage';

interface IMenuItem {
    value: string,
    text: string
}

const none = '!';
const xmlParser = new X2JS();

export const WelcomePage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user, setUser} = React.useContext(UserContext);
    const [userFound, setUserFound] = React.useState<boolean | null>(null);
    const [code, setCode] = React.useState('');
    const [vehyears, setVehyears] = React.useState<IMenuItem[]>([]);
    const [vehmakes, setVehmakes] = React.useState<IMenuItem[]>([]);
    const [vehmodels, setVehmodels] = React.useState<IMenuItem[]>([]);
    const [vehtrims, setVehtrims] = React.useState<IMenuItem[]>([]);
    const [vehyear, setVehyear] = React.useState(none);
    const [vehmake, setVehmake] = React.useState(none);
    const [vehmodel, setVehmodel] = React.useState(none);
    const [vehtrim, setVehtrim] = React.useState(none);
    const [vehtrimLabel, setVehtrimLabel] = React.useState(none);
    const [vehcustom, setVehcustom] = React.useState('');
    const [mpg, setMpg] = React.useState<number | undefined>(undefined);
    const [redirect_url, setRedirect_url] = React.useState<string | undefined>(undefined);
    const stepWizardProps = props as unknown as StepWizardChildProps;
    const [showCustomMpg, setShowCustomMpg] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(false);
    const isFirstRender = React.useRef(true);
    const loginButtonRef = React.useRef<HTMLButtonElement | null>(null);

    const allFieldsFilled = !!user?.license && !!user?.mpg;

    React.useEffect(() => {
        if (!vehyears || vehyears.length === 0) {
            fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/year").then(response => {
                return response.text();
            }).then((responseText) => {
                const json = xmlParser.xml2js(responseText) as any;
                setVehyears(json.menuItems.menuItem);
            });
        }
    }, [vehyears]);

    const login = React.useCallback(async (loginCode: string) => {
        const userResult = await Server.findUser(loginCode);
        setUser(userResult);
        setUserFound(!!userResult);
        if (userResult) {
            //overrides button fontweight onclick
            const target = loginButtonRef.current;
            if (target) {
                target.style.color = "#806b00"
                target.style.borderColor = "#806b00"
            }
            setVehyear(userResult?.vehyear?.toString() || none);
            setVehmake(userResult?.vehmake || none);
            setVehmodel(userResult?.vehmodel || none);
            setVehtrim(userResult?.vehtrim || none);
            setVehtrimLabel(userResult?.vehtrim || '');
            setMpg(userResult?.mpg);

            const queryParams = getSearchParams();
            const queryId = queryParams.get('id');
            if (queryId && queryId === loginCode) {
                // Logged in from ID in the URL, store the redirect url
                const queryRedirect = queryParams.get('redirect_url');
                if (queryRedirect) {
                    setRedirect_url(queryRedirect);
                }
            }

            if (userResult?.redirect_url && !redirect_url) {
                setRedirect_url(userResult?.redirect_url);
            }

            if (userResult.sim_status) {
                stepWizardProps.goToNamedStep(userResult.sim_status);
            }
        }
        else {
            setShowLogin(true);
        }
    }, [redirect_url, setUser, stepWizardProps]);

    const loginHandler = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        login(code);
    }

    const getSearchParams = () => {
        const params = new URLSearchParams(window.location.search);
        const newParams = new URLSearchParams();
        params.forEach((value, key) => {
            newParams.append(key.toLowerCase(), value);
        });
        return newParams;
    }

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            const queryParams = getSearchParams();
            const queryId = queryParams.get('id');
            if (queryId) {
                setCode(queryId);
                login(queryId);
            }
            else {
                setShowLogin(true);
            }
        }
    }, [login, code]);

    const handleNextClicked = async () => {
        props.nextPage();
    }

    const handleVehYearChange = (event: SelectChangeEvent) => {
        const newYear = event.target.value as string;
        setVehyear(newYear);
        setVehmake(none);
        setVehmakes([]);
        setVehmodel(none);
        setVehmodels([]);
        setVehtrim(none);
        setVehtrimLabel('');
        setVehtrims([]);
        setMpg(undefined);

        fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/make?year=" + newYear).then(response => {
            return response.text();
        }).then((responseText) => {
            const json = xmlParser.xml2js(responseText) as any;
            console.log(json);
            setVehmakes(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
        });
    };

    const handleVehMakeChange = (event: SelectChangeEvent) => {
        const newMake = event.target.value as string;
        setVehmake(newMake);
        setVehmodels([]);
        setVehmodel(none);
        setVehtrim(none);
        setVehtrimLabel('');
        setVehtrims([]);
        setMpg(undefined);

        fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/model?year=" + vehyear + "&make=" + newMake).then(response => {
            return response.text();
        }).then((responseText) => {
            const json = xmlParser.xml2js(responseText) as any;
            console.log(json);
            setVehmodels(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
        });
    };

    const handleVehModelChange = (event: SelectChangeEvent) => {
        const newModel = event.target.value as string;
        setVehmodel(newModel);
        setVehtrim(none);
        setVehtrimLabel('');
        setVehtrims([]);
        setMpg(undefined);

        fetch("https://www.fueleconomy.gov/ws/rest/vehicle/menu/options?year=" + vehyear + "&make=" + vehmake + "&model=" + newModel).then(response => {
            return response.text();
        }).then((responseText) => {
            const json = xmlParser.xml2js(responseText) as any;
            console.log(json);
            setVehtrims(_.isArray(json.menuItems.menuItem) ? json.menuItems.menuItem : [json.menuItems.menuItem]);
        });
    };

    const handleVehTrimChange = (event: SelectChangeEvent, child: React.ReactNode) => {
        console.log(child);
        const newTrim = event.target.value as string;
        setVehtrim(newTrim);
        setVehtrimLabel((child as any).props.children || '');

        fetch("https://www.fueleconomy.gov/ws/rest/vehicle/" + newTrim).then(response => {
            return response.text();
        }).then((responseText) => {
            const json = xmlParser.xml2js(responseText) as any;
            console.log(json);
            setMpg(parseFloat(json.vehicle.comb08));
        });
    };


    return (
        <BaseInfoPage
            stepWizardProps={stepWizardProps}
            useDarkTheme={true}
            userUpdates={{
                vehyear: showCustomMpg ? undefined : parseInt(vehyear),
                vehmake: showCustomMpg ? undefined : vehmake,
                vehmodel: showCustomMpg ? undefined : vehmodel,
                vehtrim: showCustomMpg ? undefined : vehtrimLabel,
                vehcustom: showCustomMpg ? vehcustom : undefined,
                mpg,
                mpg_override: showCustomMpg,
                redirect_url
            }}>
            <Box>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid xs={12}>
                        <Typography variant='h1'>
                            Welcome
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Box sx={{display:'flex'}}>
                            <img src="/images/waruc_logo_notext.jpg" alt="WA RUC Logo" style={{ width: '70px', marginRight:'10px' }} />
                            <Typography variant='h2'>
                                Washington Road Usage Charge Simulation
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            To fund our state's roads and bridges, Washington relies on the gas tax, a tax we pay every time we buy gas. As cars become more fuel efficient and electric cars become more common, we buy less gas. This means that over time, our state has less gas tax available to maintain roads and bridges. To address this challenge, we're exploring a possible replacement for the gas tax called a road usage charge.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            With a road usage charge, drivers would pay for how much they drive instead of paying by the gallon like we currently do. Drivers would either pay a gas tax or a road usage charge, but not both. 
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            This simulation imagines how a road usage charge program could work for Washington drivers like you. This simulation is for research purposes only.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{marginY: 2}} />
            <Box>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid xs={12}>
                        <Typography variant='h3'>
                            In the next steps, we'll set up your simulation experience.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            To make this experience as realistic as possible, we need you to give us a little more information.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider sx={{marginY: 2}} />
            {showLogin &&
            <Box>
                <form>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid xs={12}>
                        <Typography variant='h3'>
                            First, we need to gather your information.
                        </Typography>
                    </Grid>
                    <Grid xs={12} sx={{ marginBottom: -1.7}}>
                        <Typography variant='caption'>
                            Participant Code or Vehicle License
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <TextField sx={{ minWidth: '100%' }} value={code} onChange={(e) => setCode(e.target.value)}/>
                        {userFound === false && <Typography variant='caption' color='error.main'>Error. Please try again.</Typography>}
                            {!!userFound && <Typography variant='caption' color='success.main'>Submission Succesful!</Typography>}
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Button type="submit" variant="outlined" onClick={loginHandler} disabled={!code} ref={loginButtonRef}>Submit</Button>
                    </Grid>
                </Grid>
                </form>
            </Box>
            }
                <Box>
                    {!!user && !showCustomMpg &&
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                    Vehicle Year
                                </Typography>
                                <Select sx={{ width: '100%'}} variant='outlined' value={vehyear} onChange={handleVehYearChange} disabled={vehyears.length === 0 || allFieldsFilled}>
                                    {allFieldsFilled ?
                                    <MenuItem key={vehyear} value={vehyear}>{vehyear}</MenuItem>
                                    :
                                    [<MenuItem disabled key={none} value={none}><em>Choose</em></MenuItem>].concat(
                                    vehyears.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                    )))
                                    }
                                </Select>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                    Vehicle Make
                                </Typography>
                                <Select sx={{ width: '100%'}} variant='outlined' value={vehmake} placeholder='Choose' onChange={handleVehMakeChange} disabled={vehmakes.length === 0 || allFieldsFilled}>
                                    {allFieldsFilled ?
                                    <MenuItem key={vehmake} value={vehmake}>{vehmake}</MenuItem>
                                    :
                                    [<MenuItem disabled key={none} value={none}><em>Choose</em></MenuItem>].concat(
                                    vehmakes.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                    )))
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    }
                    {!!user && !showCustomMpg && (vehmodels.length > 0 || allFieldsFilled) &&
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                    Vehicle Model
                                </Typography>
                                <Select sx={{ width: '100%'}} variant='outlined' value={vehmodel} placeholder='Choose' onChange={handleVehModelChange} disabled={vehmodels.length === 0 || allFieldsFilled}>
                                    {allFieldsFilled ?
                                    <MenuItem key={vehmodel} value={vehmodel}>{vehmodel}</MenuItem>
                                    :
                                    [<MenuItem disabled key={none} value={none}><em>Choose</em></MenuItem>].concat(
                                    vehmodels.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                    )))
                                    }
                                </Select>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                    Vehicle Trim
                                </Typography>
                                <Select sx={{ width: '100%'}} variant='outlined' value={vehtrim} placeholder='Choose' onChange={handleVehTrimChange} disabled={vehtrims.length === 0 || allFieldsFilled}>
                                    {allFieldsFilled ?
                                    <MenuItem key={vehtrim} value={vehtrim}>{vehtrim}</MenuItem>
                                    :
                                    [<MenuItem disabled key={none} value={none}><em>Choose</em></MenuItem>].concat(
                                        vehtrims.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
                                    )))
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    }
                    {!!user && !showCustomMpg && !allFieldsFilled &&
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid xs={12}>
                                <Link sx={{cursor: 'pointer'}} onClick={() => setShowCustomMpg(true)}>
                                    Don't see your vehicle?
                                </Link>
                            </Grid>
                        </Grid>
                    }
                    {!!user && showCustomMpg &&
                        <Grid container rowSpacing={2} columnSpacing={1}>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                Vehicle's description
                                </Typography>
                                <TextField sx={{ minWidth: '100%'}} variant='outlined' value={vehcustom} placeholder='Year Make Model' onChange={(e) => setVehcustom(e.target.value)} />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Typography variant='caption'>
                                Vehicle’s average miles per gallon
                                </Typography>
                                <TextField sx={{ minWidth: '100%'}} variant='outlined' type="number" value={mpg} placeholder='00' onChange={(e) => setMpg(parseFloat(e.target.value))} />
                            </Grid>
                            <Grid xs={12}>
                                <Link sx={{cursor: 'pointer'}} onClick={() => setShowCustomMpg(false)}>
                                    Find your vehicle.
                                </Link>
                            </Grid>
                        </Grid>
                    }
                    {!!user &&
                        <Grid container rowSpacing={2} columnSpacing={1} marginTop='2.125rem'>
                            <Grid xs={12}>
                                <Typography variant="caption">
                                    Average Miles Per Gallon or Equivalent
                                </Typography>
                                <Typography variant="h4">
                                    {mpg ? mpg : "--" } mi.
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Box>
                <Divider sx={{marginY: 2}} />
                <Box>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid xs={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Box>
                                <img src="/images/pilot.svg" alt="Pilot"/>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={9}>
                            <Typography variant="h3" >
                                Keep an eye out for the simulation "SIM" icon.
                            </Typography>
                            <Typography sx={{ paddingTop: 1}}>
                                Because this is a research simulation, not all features are functional. When the simulation icon appears, simply follow the prompts to continue the simulation.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Divider sx={{marginY: 2}} />
                <Box sx={{ paddingBottom: '1rem' }}>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid xs={12}>
                            <Button sx={{float: 'right'}} variant="contained" onClick={handleNextClicked} disabled={!user || !mpg || (showCustomMpg && !vehcustom)}>Next</Button>
                        </Grid>
                    </Grid>
                </Box>
        </BaseInfoPage>
    );
}

WelcomePage.defaultProps = {
    stepName: SimSteps.Welcome.name
};
