import { Box, ThemeProvider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { HeaderToggleButtonGroup } from '../components';
import { UserContext } from '../contexts';
import { Discounts, SimSteps } from '../models';
import * as Themes from '../themes';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const DiscountsPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [inc_discount, setInc_discount] = React.useState(Discounts.No);

    React.useEffect(() => {
        if (user) {
            setInc_discount(user.inc_discount ? Discounts.Yes : Discounts.No);
        }
    }, [user]);

    const handleAcceptClick = async () => {
        return true;
    }

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            acceptOnClick={handleAcceptClick}
            userUpdates={{
                inc_discount: inc_discount === Discounts.Yes
            }}
            title="To qualify for a discount, are you currently enrolled in any state assistance services such as Washington Supplemental Nutrition Assistance Program (SNAP), Apple Health (Medicaid), or Low-Income Home Energy Assistance Program (LIHEAP)?"
            learnMoreLink="Learn more about discounts from state assistance services."
            learnMoreContent={
                <Grid container rowSpacing={1} columnSpacing={0}>
                    <Grid xs={12}>
                        <Typography variant='h2'>
                            This program is designed to provide partial payment relief for drivers eligible for state assistance services.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            Indicating your eligibility for state assistance programs (i.e., Washington Supplemental Nutrition Assistance Program (SNAP), Apple Health (Medicaid), Low-Income Home Energy Assistance Program (LIHEAP) allows us to verify your eligibility. If you are eligible for any of these state services, a 20% discount will be provided against your total payment due.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Box sx={{paddingTop: '2.75rem', maxWidth: '100%'}}>
                            <ThemeProvider theme={Themes.Dark}>
                                <Grid container columnSpacing={1} bgcolor='background.default' sx={{marginX : 0}}>
                                    <Grid xs={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Box>
                                            <img src="/images/pilot.svg" alt="Pilot"/>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={9} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%'}}>
                                            <Typography>
                                                In a real program, your eligibility would be verified through additional steps.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        </Box>
                    </Grid>
                </Grid>
            }>
            <HeaderToggleButtonGroup
                value={inc_discount}
                values={Discounts}
                onChange={setInc_discount}
            />
        </BaseInputPage>
    );
}

DiscountsPage.defaultProps = {
    stepName: SimSteps.Discounts.name
};
