import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { FAQ } from '../components';
import { SimSteps } from '../models';
import BaseInfoPage from './BaseInfoPage';
import { StepWizardPageProps } from './BasePage';

export const IntroFAQPage: React.FunctionComponent<StepWizardPageProps> = (props) => {

    return (
        <BaseInfoPage
            useDarkTheme={false}
            stepWizardProps={props as unknown as StepWizardChildProps}
            showLogo={true}
            userUpdates={{}}
            >
            <Box sx={{ paddingTop: 1}}>
                <Grid container rowSpacing={2}>
                    <Grid xs={12}>
                        <Typography variant='h1'>
                            Washington Road Usage Charge Program
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                        As vehicles become less dependent on gas, Washington is transitioning from funding roads through the state gas tax and gallons purchased, to a road usage charge based upon the number of miles driven.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                        The objective of this program is for drivers to pay based on their use of Washington's roads, regardless of what type of vehicle they drive.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Button variant="contained" onClick={props.nextPage}>Get Started</Button>
                    </Grid>
                </Grid>
            </Box>
            <FAQ sx={{ paddingTop: 6.93, paddingBottom: '3.13rem' }}/>
        </BaseInfoPage>
    );
}

IntroFAQPage.defaultProps = {
    stepName: SimSteps.IntroFAQ.name
};
