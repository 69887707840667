import { Box, Link, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { StepWizardChildProps } from 'react-step-wizard';
import { BasicModal } from '../components';
import { UserContext } from '../contexts';
import { SimSteps } from '../models';
import { BaseInputPage } from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const MileageReportingPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [odo, setOdo] = useState<number | ''>('');
    const [mi_est, setMi_est] = useState<number | ''>('');
    const [showDialog, setShowDialog] = React.useState(false);
    const [showCustomMileage, setShowCustomMileage] = React.useState(false);

    React.useEffect(() => {
        setOdo((user && user.odo) ? user.odo : '');
        setMi_est((user && user.mi_est) ? user.mi_est : '');
    }, [user]);

    const calculateMileage = (totalMiles: number) => {
        setMi_est(Math.round(totalMiles / (user?.yearsOld() || 1)));
    }

    const odoChanged = (newValue: string) => {
        const entry = parseInt(newValue);
        setOdo(entry);
        if (!showCustomMileage) {
            calculateMileage(entry);
        }
    };

    const handleShowCustom = (show: boolean) =>  {
        if (!show) {
            calculateMileage(odo || 0);
        }

        setShowCustomMileage(show);
    }

    const dialogOnClose = (event: object, reason: string) => {
        if (reason === "accept") {
            props.nextPage();
        }

        setShowDialog(false);
    };

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            canNavigateForward={odo > 0 && mi_est > 0 && odo >= mi_est}
            userUpdates={{
                odo: odo || undefined,
                mi_est: mi_est || undefined
            }}
            acceptOnClick={async () => {
                if ((mi_est > 20000 || mi_est < 2000) && mi_est !== user?.mi_est) {
                    setShowDialog(true);
                    return false;
                }

                return true;
            }}
            title="What is the current mileage on your vehicle’s odometer?"
            learnMoreLink="Learn how to find your odometer"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant='h2'>
                        An odometer is  used to display the distance traveled by your vehicle.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                        To read your odometer, look on your vehicle's dashboard for a small rectangle containing numbers. In newer vehicles, the odometer is probably electronic. In older vehicles, the odometer may be mechanical.
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{maxWidth: '16.125rem'}} src='/images/odometer1.png' alt='Odometer' />
                    </Grid>
                    <Grid xs={12} md={6} sx={{display: 'flex', justifyContent: 'center'}}>
                        <img style={{maxWidth: '16.126rem'}}src='/images/odometer2.png' alt='Odometer' />
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                        Beware that many cars often contain secondary odometers, usually labeled Trip, that you can reset to zero to measure short trip distances. Electronic odometers  include a toggle to switch between the odometer and trip odometers. Mechanical odometers often include both the trip odometer and the odometer stacked on top of one another. The odometer is the larger number. When reporting your mileage, be careful to look at the odometer and not the trip odometer. If you have questions about the odometer for your vehicle, consult your vehicle owner's manual for assistance.
                        </Typography>
                    </Grid>
                </Grid>
            }
            >
            <Grid container spacing={1} sx={{marginTop: "-1.5rem"}}>
                <Grid xs={12} md={4}>
                    <Box>
                        <Typography variant="h4" fontWeight='500'>
                        Odometer Mileage
                        </Typography>
                        <br/>
                        <NumberFormat
                            sx={{marginTop: -1}}
                            thousandSeparator=','
                            customInput={TextField}
                            onValueChange={(values) => odoChanged(values.value)}
                            value={odo}
                        />
                    </Box>
                </Grid>
                <Grid xs={12} md={8}>
                    {showCustomMileage
                      ? <Box>
                            <Typography variant="h4" fontWeight='500'>
                                Miles Driven Over Previous 12 Months
                            </Typography>
                            <br/>
                            <Typography variant="subtitle1">
                            <NumberFormat
                                sx={{marginTop: -1}}
                                thousandSeparator=','
                                customInput={TextField}
                                onValueChange={(values) => setMi_est(parseInt(values.value))}
                                value={mi_est}
                            />
                            </Typography>
                            <br/>
                            <Link sx={{cursor: 'pointer'}} onClick={() => handleShowCustom(false)}>
                                Auto-calculate miles driven.
                            </Link>
                        </Box>
                      : <Box>
                            <Typography variant="h4" fontWeight='500'>
                                Estimated Miles Driven Over Previous 12 Months
                            </Typography>
                            <br/>
                            <Typography variant="h2" sx={{
                                marginTop: '-0.6rem',
                                paddingBottom: '0.85rem',
                                fontWeight: 600
                                }}>
                                {(mi_est && mi_est.toLocaleString('en-US')) || '--'} mi.
                            </Typography>
                            <br/>
                            <Link sx={{cursor: 'pointer'}} onClick={() => handleShowCustom(true)}>
                                Estimated miles driven look off? Enter it manually here.
                            </Link>
                        </Box>
                    }
                </Grid>
            </Grid>
            <BasicModal
                open={showDialog}
                onClose={dialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Confirm"
                title={(mi_est as number).toLocaleString('en-US') + (mi_est < 2000 ? " is not many miles!" : " is a lot of miles!")}
                >
                <Typography>
                    <b>{odo.toLocaleString('en-US')}</b> is the odometer mileage you entered. Are you sure that mileage is correct?
                </Typography>
            </BasicModal>
        </BaseInputPage>
    );
}

MileageReportingPage.defaultProps = {
    stepName: SimSteps.MileageReporting.name
};
