import { ToggleButtonGroup } from '@mui/material';
import * as React from 'react';
import _ from 'lodash';

export interface BaseToggleButtonGroupProps {
    requireValue?: boolean,
    multiSelect?: boolean,
    value: any | any[],
    children?: React.ReactNode,
    onChange: (value: any) => void,
}

export const BaseToggleButtonGroup: React.FunctionComponent<BaseToggleButtonGroupProps> = (props) => {
    const handleValueChanged = (e: React.MouseEvent<HTMLElement, MouseEvent>, newValue:string) => {
        if (!props.requireValue || (_.isArray(newValue) && newValue.length > 0) || (!_.isArray(newValue) && newValue)) {
            props.onChange(newValue);
        }
    }

    return (
        <ToggleButtonGroup
            value={props.value}
            exclusive={!props.multiSelect}
            onChange={handleValueChanged}
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gridGap: '1rem',
                minHeight: 'auto',
                marginBottom: '1.25rem'
            }}>
            {props.children}
        </ToggleButtonGroup>
    );
}

BaseToggleButtonGroup.defaultProps = {
    requireValue: true,
    multiSelect: false
}
