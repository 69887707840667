export const SimSteps = {
    Welcome: {name: 'Welcome', stepNumber: 1},
    Intro: {name: 'Intro', stepNumber: 2},
    IntroFAQ: {name: 'IntroFAQ', stepNumber: 3},
    MileageReporting: {name: 'MileageReporting', stepNumber: 4},
    MileageVerification: {name: 'MileageVerification', stepNumber: 5},
    Exemption: {name: 'Exemption', stepNumber: 6},
    Discounts: {name: 'Discounts', stepNumber: 7},
    ReportingOption: {name: 'ReportingOption', stepNumber: 8},
    PaymentPlan: {name: 'PaymentPlan', stepNumber: 9},
    PaymentMethod: {name: 'PaymentMethod', stepNumber: 10},
    Review: {name: 'Review', stepNumber: 11},
    Confirmation: {name: 'Confirmation', stepNumber: 12},
}

export const UploadPicMethods = {
    None: '',
    Upload: 'Upload Now',
    Text: 'Send Via Text',
    QrCode: 'Scan QR Code',
    Decline: 'Decline Submission'
}

export const Exemptions = {
    None: {value: 'None', header: undefined, body: undefined },
    LessThan200: {value: 'Less than 200 Miles', header: undefined, body: 'Adds a standard exemption to your Road Use Charge.' },
    MoreThan200: {value: 'More than 200 Miles', header: undefined, body: 'Requires photo evidence to claim exemptions.' }
}

export const Discounts = {
    No: 'No',
    Yes: 'Yes',
}

export const ReportingOptions = {
    Self: 'Self-Reporting',
    Telematics: 'Vehicle Telematics',
    Device: 'Installed Device',
    Mobile: 'Mobile App',
}

export const PaymentPlans = {
    Today: { value: 'Pay Today', header: undefined, body: 'Pay your entire charge today.', bodySub: 'Card transaction fees may apply.' },
    FourPayments: { value: 'Make Four Equal Payments', header: undefined, body: 'Pay four installments starting today.', bodySub: 'Card transaction fees may apply.'},
}

export const PaymentMethods = {
    CreditCard: {value: 'Credit/Debit', header: undefined, body: 'Card transaction fees may apply.'},
    BankAccount: {value: 'Bank Account', header: undefined, body: 'No additional fees.' },
    PaymentApp: { value: 'Payment App', header: undefined, body: 'PayPal, Venmo, or Cash App.', bodySub: 'No additional fees.' },
    CashCheck: {value: 'Cash/Check', header: undefined, body: 'No additional fees.' },
}

export const OptInMessage = {
    Intro: 'Because you selected this option, you may be eligible for follow-on research which may include additional incentives.',
    Body: 'Future research will study how people report exempt miles, use technology to track their mileage, and manage payments. Would you like to opt-in to these additional research opportunities?'
}

export const SurveyUrl = {
    Ei: 'https://survey.alchemer.com/s3/7034297/WARUCsurvey/',
    Yates: 'https://yahoo.com/',
    Mail: 'https://usps.com/'
}
