import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { HeaderBodyToggleButtonGroup, PilotModal } from '../components';
import { UserContext } from '../contexts';
import { OptInMessage, PaymentPlans, SimSteps } from '../models';
import { Colors } from '../themes/Common';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const PaymentPlanPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [pay_opt, setPay_opt] = React.useState(PaymentPlans.Today.value);
    const [showFollowupDialog, setShowFollowupDialog] = React.useState(false);
    const [follow_opt, setFollow_opt] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
        setPay_opt((user && user.pay_opt) ? user.pay_opt : PaymentPlans.Today.value);
        setFollow_opt(user?.follow_opt || undefined);
    }, [user]);

    const handleAcceptClick = async () => {
        if (pay_opt === PaymentPlans.FourPayments.value) {
            if (user?.recruit !== 'IPSOS' && follow_opt === undefined) {
                setFollow_opt(true);
                setShowFollowupDialog(true);
                return false;
            }
        }
        return true;
    }

    const followupDialogOnClose = async (_e: object, reason: string) => {
        setShowFollowupDialog(false);
        if (reason === "accept") {
            await props.nextPage();
        }
        else {
            setFollow_opt(undefined);
        }
    };

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            acceptOnClick={handleAcceptClick}
            userUpdates={{
                pay_opt,
                follow_opt
            }}
            title="How would you like to pay for your usage charge?"
            learnMoreLink="Learn more about payment options"
            learnMoreContent={
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <Typography variant="h2">
                            Payment Plans provide a method for splitting your road usage charge over time.
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            If you opt into four equal payments, the first payment will be due today. Failure to complete all payments on schedule may result in fines and penalties including the inability to register your vehicle.
                        </Typography>
                    </Grid>
                </Grid>
            }>
            <HeaderBodyToggleButtonGroup
                value={pay_opt}
                values={PaymentPlans}
                onChange={setPay_opt}
            />
            <Typography fontStyle='italic' color={Colors.gray4} fontSize='0.9375rem'>
                Please select your preferred option. No payment will be collected as part of this simulation.
            </Typography>
            <PilotModal
                open={showFollowupDialog}
                onClose={followupDialogOnClose}
                abortLabel="Cancel"
                acceptLabel="Continue"
                title="Thank you for choosing to pay in four equal installments.">
                <Box>
                    <Typography marginBottom="1rem">
                        {OptInMessage.Intro}
                    </Typography>
                    <Typography marginBottom="1rem">
                        {OptInMessage.Body}
                    </Typography>
                    <FormControlLabel label="Yes, I want to participate in further research." control={
                        <Checkbox checked={!!follow_opt} onChange={(e) => setFollow_opt(e.target.checked)}/>
                    }/>
                </Box>
            </PilotModal>
        </BaseInputPage>
    );
}

PaymentPlanPage.defaultProps = {
    stepName: SimSteps.PaymentPlan.name
};
