import { Box, Link, Typography } from '@mui/material';
import * as React from 'react';
import { ShowLearnMoreContext } from '../contexts';
import BasePage, { BasePageProps } from './BasePage';

export interface BaseInputPageProps extends BasePageProps {
    title: string;
    learnMoreLink?: string;
}

export const BaseInputPage: React.FunctionComponent<BaseInputPageProps> = (props) => {
    const {setShowLearnMore} = React.useContext(ShowLearnMoreContext);

    return (
        <BasePage {...props}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection:'column',
                gridGap: '1.1875rem',
                paddingTop: '4.6825rem'
                }}>
                <Typography variant="h2">
                    {props.title}
                </Typography>
                {!!props.learnMoreLink && !!props.learnMoreContent &&
                <Link sx={{cursor: 'pointer'}} onClick={() => setShowLearnMore(true)}>{props.learnMoreLink}</Link>
                }
                <Box sx={{marginTop: '2.1875rem'}}>
                    {props.children}
                </Box>
            </Box>

        </BasePage>
    );
}

BaseInputPage.defaultProps = {
    showCostEstimate: true,
    showLogo: true,
    showNavigationButtons: true,
    showProgress: true
}

export default BaseInputPage;
