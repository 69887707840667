import { Box, ToggleButton, Typography } from '@mui/material';
import * as React from 'react';
import _ from 'lodash';
import { BaseToggleButtonGroup, BaseToggleButtonGroupProps } from '.';

export interface HeaderToggleButtonGroupProps extends Omit<BaseToggleButtonGroupProps, 'children'> {
    values: string[] | {[index: string] : string},
}

export const HeaderToggleButtonGroup: React.FunctionComponent<HeaderToggleButtonGroupProps> = (props) => {
    return (
        <BaseToggleButtonGroup {...props}>
            {_.map(props.values, (item: string) =>
                <ToggleButton key={item} value={item} sx={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
                    <Box>
                        <Typography variant='h3' textAlign="left" marginY='auto'>
                            {item}
                        </Typography>
                    </Box>
                </ToggleButton>
            )}
        </BaseToggleButtonGroup>
    );
}

HeaderToggleButtonGroup.defaultProps = {
    requireValue: true,
    multiSelect: false
}
