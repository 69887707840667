import * as React from 'react';

export const ShowSummaryContext = React.createContext<{
    showSummary: boolean,
    panelWidth: number,
    setShowSummary: (showSummary: boolean) => void}>({
        showSummary: false,
        panelWidth: 0,
        setShowSummary: (showSummary: boolean) => {}
});
