import { Box, Button, CssBaseline, Divider, Grid, Link, ThemeProvider, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import StepWizard from "react-step-wizard";
import { ContentMarginBox, LearnMoreDrawer } from './components';
import SummaryDrawer from './components/SummaryDrawer';
import { PageStateContext, ShowLearnMoreContext, ShowSummaryContext, UserContext } from './contexts';
import * as Globals from './Globals';
import { Exemptions, IPageState, IUser, PaymentMethods } from './models';
import { ExemptionPage, IntroPage, MileageReportingPage, MileageVerificationPage, PaymentMethodPage, PaymentPlanPage, ReportingOptionPage, ReviewPage, WelcomePage } from './pages';
import { ConfirmationPage } from './pages/ConfirmationPage';
import { DiscountsPage } from './pages/DiscountsPage';
import { IntroFAQPage } from './pages/IntroFAQPage';
import * as Server from './server';
import * as Themes from './themes';
import Animate from './themes/animate.module.css';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from './AppInsights';

const nextOnlyAnimations = {
    enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
    enterLeft: `${Animate.instant} ${Animate.fadeInLeft}`,
    exitRight: `${Animate.instant} ${Animate.fadeOutRight}`,
    exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
}

const noAnimations = {
    enterRight: `${Animate.instant} ${Animate.fadeInRight}`,
    enterLeft: `${Animate.instant} ${Animate.fadeInLeft}`,
    exitRight: `${Animate.instant} ${Animate.fadeOutRight}`,
    exitLeft: `${Animate.instant} ${Animate.fadeOutLeft}`,
}

const allAnimations = {
    enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
    enterLeft: `${Animate.animated} ${Animate.fadeInLeft}`,
    exitRight: `${Animate.animated} ${Animate.fadeOutRight}`,
    exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
}

function App() {
    const [stepWizard, setStepWizard] = React.useState<any>(null);
    const [user, setUser] = React.useState<IUser | null>(null);
    const [percentDone, setPercentDone] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [showSummary, setShowSummary] = React.useState(false);
    const [pageStates, setPageStates] = React.useState<{[index: number]: IPageState}>({});
    const [currentPageState, setCurrentPageState] = React.useState<IPageState | undefined>(undefined);
    const [contentWidth, setContentWidth] = React.useState(0);
    const [showLearnMore, setShowLearnMore] = React.useState(false);
    const [canAdvance, setCanAdvance] = React.useState(true);
    const [serverIsReady, setServerIsReady] = React.useState(false);
    const contentRef = React.useRef<HTMLElement>();

    const mergedUser = {...user, ...(currentPageState?.userUpdates)} as IUser;

    const pruneUnchanged = (newUser: IUser, oldUser: IUser) => {
        return _.transform(newUser, (result: any, value, key) => {
            if (!_.isEqual(value, oldUser[key])) {
                result[key] = value;
            }
        });
    }

    const userContext = {
        user,
        setUser: (newUser: IUser | null) => {
            if (newUser) {
                newUser.yearsOld = function() {
                    if (this.vehyear) {
                        return Math.max(new Date().getFullYear() + 1 - this.vehyear, 1);
                    }
                    return 1;
                };
                newUser.effectiveExemptMiles = function() {
                    if (this.exempt) {
                        if (this.exempt === Exemptions.MoreThan200.value) {
                            return Math.max(200, this.exempt_mi || 0);
                        }
                        else if (this.exempt === Exemptions.LessThan200.value) {
                            return 200;
                        }
                    }
                    return 0;
                };
                newUser.estimatedRucFee = function() {
                    return this.chargeableMiles() * Globals.dollarsPerMile;
                };
                newUser.chargeableMiles = function() {
                    return this.mi_est ? (Math.max(0, this.mi_est - this.effectiveExemptMiles())) : 0;
                };
                newUser.estimatedGasFee = function() {
                    return (this.mi_est && this.mpg && this.mpg < 63)
                        ? this.chargeableMiles() / this.mpg * Globals.dollarsPerGallon
                        : 0;
                };
                newUser.subTotal = function() {
                    return Math.max(0, this.estimatedRucFee() - this.discountAmount())
                }
                newUser.totalBeforeFees = function() {
                    return Math.max(0, this.subTotal() - this.estimatedGasFee());
                };
                newUser.totalDue = function() {
                    return this.totalBeforeFees() + this.transactionFee();;
                };
                newUser.transactionFee = function() {
                    return this.pay_method === PaymentMethods.CreditCard.value ? this.totalBeforeFees() * Globals.transactionFeePercent : 0;
                };
                newUser.discountAmount = function() {
                    return this.hasDiscount() ? this.estimatedRucFee() * Globals.discountPercent : 0;
                };
                newUser.hasDiscount = function() {
                    return !!this.inc_discount;
                };

                appInsights.setAuthenticatedUserContext(newUser.id);
            }
            else {
                appInsights.clearAuthenticatedUserContext();
            }
            setUser(newUser);
        },
        updateUser: async (newUser: Partial<IUser>) => {
            if (user && newUser) {
                // Prune unchanged fields
                newUser = pruneUnchanged(newUser as IUser, user);

                if (Object.keys(newUser).length > 0) {
                    const updatedUser = await Server.updateUser(user.id, newUser as IUser);
                    userContext.setUser(updatedUser);
                    return !!(updatedUser?.id);
                }
            }

            return true;
        }
    };

    const pageStatesContext = {
        setPageState: (step: number, state: IPageState) => {
            const copy = {...pageStates};
            copy[step] = state;
            setPageStates(copy);

            if (state.isActive) {
                setCurrentPageState(state);
            }
        }
    }

    const showSummaryContext = {
        showSummary,
        panelWidth: 300,
        setShowSummary: (value: boolean) => {
            setShowSummary(value);
            appInsights.trackEvent({ name: value ? "ShowSummary" : "HideSummary" }, { page: getStepName() });
        }
    }

    const getStepName = (step = activeStep)  => {
        const steps = stepWizard.getSteps();
        if (step <= steps.length) {
            return steps[step-1].props.stepName;
        }

        return null;
    }

    const showLearnMoreContext = {
        showLearnMore,
        setShowLearnMore: (value: boolean) => {
            setShowLearnMore(value);
            appInsights.trackEvent({ name: value ? "ShowLearnMore" : "HideLearnMore" }, { page: getStepName() });
        }
    }

    const handleStepChange = (stepChange: { previousStep: number, activeStep: number }) => {
        window.scrollTo(0, 0);
        setActiveStep(stepChange.activeStep);
        setPercentDone((stepChange.activeStep - 4) / (stepWizard.totalSteps - 4) * 100);
        setShowLearnMore(false);
        if (stepChange.activeStep === user?.sim_step) {
            setCanAdvance(false);
            setTimeout(() => {
                setCanAdvance(true);
            }, Globals.pageAdvanceMinTime); 
        }

        const stepName = getStepName(stepChange.activeStep);
        appInsights.trackPageView({ name: stepName, uri: stepChange.activeStep.toString(), refUri: stepChange.previousStep.toString()});
    };

    const abortClick = async () => {
        let proceed = true;
        if (currentPageState) {
            if (currentPageState.abortOnClick) {
                proceed = await currentPageState.abortOnClick();
            }

            if (proceed) {
                await previousPage();
            }
        }
    }

    const acceptClick = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        let proceed = true;
        if (currentPageState) {
            if (currentPageState.acceptOnClick) {
                proceed = await currentPageState.acceptOnClick();
            }

            if (proceed) {
                await nextPage();
            }
        }
    }

    const previousPage = async () => {
        stepWizard.previousStep();
    }

    const nextPage = async () => {
        const userData = currentPageState?.userUpdates || {};
        const nextStep = activeStep + 1;
        const stepName = getStepName(nextStep);

        if (nextStep > (user?.sim_step || 0) && stepName) {
            userData.sim_step = nextStep;
            userData.sim_status = stepName;
        }

        await userContext.updateUser(userData);
        stepWizard.nextStep();
    }

    const updateContentWidth = () => {
        const element = (contentRef?.current as HTMLElement);
        if (element) {
            setContentWidth(element.clientWidth + element.offsetLeft);
        }
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateContentWidth);
        Server.getClientKey().then((response) => {
            if (response && response.key) {
                Server.setApiKey(response.key);
            }
            setServerIsReady(true);
        });
        return () => {
            window.removeEventListener("resize", updateContentWidth);
        }
    }, []);

    React.useEffect(() => {
        updateContentWidth();
    }, [showSummaryContext.showSummary, currentPageState?.showCostEstimate]);

    return (
        <React.StrictMode>
            <CssBaseline/>
            <ThemeProvider theme={Themes.Light}>
            <UserContext.Provider value={userContext}>
            <ShowLearnMoreContext.Provider value={showLearnMoreContext}>
            <ShowSummaryContext.Provider value={showSummaryContext}>
            <PageStateContext.Provider value={pageStatesContext}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <Box sx={{
                    display: "flex",
                    flexDirection:'row'
                }}>
                    <Box flexGrow={1} display='flex' flexDirection='column'>
                        <Box sx={{ height: '0.5rem', marginBottom: '-0.5rem', visibility: currentPageState?.showProgress ? 'visible' : 'hidden', backgroundColor: '#E6E6E6', borderTopStyle: 'solid', borderTopWidth: '0.0625rem', borderColor: 'action.disabled', zIndex: 10 }}>
                            <Box sx={{ height: '0.5rem', backgroundColor: '#81A53E', width: percentDone.toString() + '%' }}></Box>
                        </Box>
                        <ContentMarginBox sx={{marginBottom: '-7.125rem', height:'7.125rem', pointerEvents: 'none', zIndex: 10}}>
                            <Grid flexGrow={1} container sx={{ marginX: 'auto', marginTop: '1.4375rem' }}>
                                <Grid xs={8} md={9}>
                                    <Box marginTop='0.1875rem' sx={{ display: currentPageState?.showLogo ? 'block' : 'none' }}>
                                        <img src="/images/waruc_logo.png" alt="WA RUC Logo" style={{transform: 'translate(-9px)', width:'198px'}}/>
                                    </Box>
                                </Grid>
                                <Grid xs={4} md={3} id="trackmetop" sx={{display:{xs:'block', lg:'none'}}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'right', height: 0 }} ref={contentRef}/>
                                    <Box sx={{ display: currentPageState?.showCostEstimate ? 'block' : 'none' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'right'}}>
                                            <Typography variant="h6" sx={{marginRight: '0.2rem', marginTop: '0.125rem'}}>$</Typography>
                                            <Typography variant="h5">{mergedUser && mergedUser.totalDue && mergedUser.totalDue().toFixed(2)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '0.23rem'}}>
                                            <Typography>
                                                Last Year's Estimated Total
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '-0.3125rem', pointerEvents:'all'}}>
                                            <Link onClick={() => showSummaryContext.setShowSummary(!showSummary)} sx={{cursor:'pointer'}}> {showSummary ? 'Hide Details' : 'Show Details'} </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ContentMarginBox>
                        { serverIsReady && 
                            <StepWizard
                                onStepChange={handleStepChange}
                                instance={setStepWizard}
                                isLazyMount={false}
                                transitions={activeStep > 4 ? allAnimations
                                    : activeStep === 4 ? nextOnlyAnimations
                                    : noAnimations}>
                                <WelcomePage nextPage={nextPage} previousPage={previousPage}/>
                                <IntroPage nextPage={nextPage} previousPage={previousPage}/>
                                <IntroFAQPage nextPage={nextPage} previousPage={previousPage}/>
                                <MileageReportingPage nextPage={nextPage} previousPage={previousPage}/>
                                <MileageVerificationPage nextPage={nextPage} previousPage={previousPage}/>
                                <ExemptionPage nextPage={nextPage} previousPage={previousPage}/>
                                <DiscountsPage nextPage={nextPage} previousPage={previousPage}/>
                                <ReportingOptionPage nextPage={nextPage} previousPage={previousPage}/>
                                <PaymentPlanPage nextPage={nextPage} previousPage={previousPage}/>
                                <PaymentMethodPage nextPage={nextPage} previousPage={previousPage}/>
                                <ReviewPage nextPage={nextPage} previousPage={previousPage}/>
                                <ConfirmationPage nextPage={nextPage} previousPage={previousPage}/>
                            </StepWizard>
                        }
                        <ContentMarginBox sx={{ marginTop: '-12rem', height: '7.125rem', pointerEvents: 'none', zIndex: 10, display:{xs:'none', lg:'block'}}}>
                            <Grid xs={4} md={3} id="trackme">
                                <Box sx={{ display: 'flex', justifyContent: 'right', height: 0 }} ref={contentRef} />
                                <Box sx={{ display: currentPageState?.showCostEstimate ? 'block' : 'none' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="h6" sx={{ marginRight: '0.2rem', marginTop: '0.125rem' }}>$</Typography>
                                        <Typography variant="h5">{mergedUser && mergedUser.totalDue && mergedUser.totalDue().toFixed(2)}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '0.23rem' }}>
                                        <Typography>
                                            Last Year's Estimated Total
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '-0.3125rem', pointerEvents: 'all' }}>
                                        <Link onClick={() => showSummaryContext.setShowSummary(!showSummary)} sx={{ cursor: 'pointer' }}> {showSummary ? 'Hide Details' : 'Show Details'} </Link>
                                    </Box>
                                </Box>
                            </Grid>
                        </ContentMarginBox>
                        <LearnMoreDrawer open={showLearnMore} onClose={() => showLearnMoreContext.setShowLearnMore(false)} summaryShown={showSummary && !!currentPageState?.showCostEstimate}>
                            {currentPageState?.learnMoreContent}
                        </LearnMoreDrawer>
                    </Box>
                    <SummaryDrawer
                        open={showSummary && !!currentPageState?.showCostEstimate}
                        desktopWidth={showSummaryContext.panelWidth}
                        user={mergedUser}
                        onClose={() => showSummaryContext.setShowSummary(false)}
                        stepWizard={stepWizard}
                        />
                </Box>
                <Box sx={{ position:'fixed', bottom: 0, width: '100%', zIndex: 10, visibility: currentPageState?.showNavigationButtons ? 'visible' : 'hidden'}} bgcolor="background.default">
                    <Divider />
                    <Box sx={{width: {xs:'96%', lg: contentWidth}, marginY: '0.625rem', position: 'relative'}} id='buttontracker'>
                        <Box sx={{ display: 'flex', justifyContent: 'right', gridGap: "0.5rem"}}>
                            <Button onClick={abortClick} disabled={!(currentPageState?.canNavigateBack)}>Previous</Button>
                            <Button type="submit" onClick={acceptClick} variant="contained" disabled={!canAdvance || !currentPageState?.canNavigateForward}>{currentPageState?.nextButtonLabel || 'Next'}</Button>
                        </Box>
                    </Box>
                </Box>
            </AppInsightsContext.Provider>
            </PageStateContext.Provider>
            </ShowSummaryContext.Provider>
            </ShowLearnMoreContext.Provider>
            </UserContext.Provider>
            </ThemeProvider>
        </React.StrictMode>
    );
}

export default App;
