import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, SxProps, Theme, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import Markup from 'react-html-markup';
import { ExpandArrow } from '../icons';

interface QandA {
    question: string,
    answer: string[]
}

const qAndAs: QandA[] = [
    {
        question: "What is a road usage charge?",
        answer: [
            "A road usage charge is a per-mile charge drivers would pay based on how much they use Washington’s road system rather than by the gallons of gas they buy.",
        ]
    },
    {
        question: "What is the per-mile rate of the Road Usage Charge?",
        answer: [
            "The rate is 2.4 cents per mile. This is what the average Washington vehicle currently pays in state gas taxes at 49.4 cents per gallon. However, most vehicles actually pay more or less than this number. Under a road usage charge, all vehicles would pay the same. Ultimately it is up to the Legislature to establish a rate.",
        ]
    },
    {
        question: "Why is Washington state studying a road usage charge system?",
        answer: [
            "<u>To ensure sustainable, long-term funding</u>: As vehicles become more fuel efficient, gas consumption goes down. With a decline in gas consumption comes reduced gas tax revenues needed for our roads, bridges, and ferry system. A road usage charge could provide a more stable source of transportation funding than the gas tax since drivers would pay by the mile instead of by the gallon. A per-mile charge more directly ties road usage to costs.",
            "<u>To ensure everyone pays their fair share</u>: Considering the range of miles per gallon (mpg) of today’s vehicles on the road, the gas tax has become inequitable. For the same miles driven, drivers pay widely different amounts for their roadway use depending on their vehicle’s miles per gallon. This inequity is expected to grow each year as vehicle miles per gallon continues to increase.",
        ]
    },
    {
        question: "Will this be an additional tax?",
        answer: [
            "No, the road usage charge is being considered as a replacement to the gas tax, not in addition to the gas tax. On your summary of charges you will notice that we have estimated the amount paid in gas tax based on your vehicle’s miles per gallon and reported mileage. This amount has been deducted from your final road usage charge amount to avoid double payment.",
        ]
    },
    {
        question: "What is this simulation exploring?",
        answer: [
            "This road usage charge simulation is a small-scale, short-term study that gives Washington drivers a chance to experience different approaches to road usage charging. This research simulation also gives drivers an idea of how a real system could work and provides an opportunity to share their feedback. Decision makers will use this feedback to understand the pros and cons of this approach to funding our transportation system statewide.",
        ]
    },
    {
        question: "What happens if I drive out of state or in private roads?",
        answer: [
            "If a road usage charge system is put in place, the Legislature must decide whether and how to allow Washington drivers to avoid paying for miles they drive out of state or off public roads. One way is to allow a standard exemption for all drivers. Another way is to allow drivers to claim exemptions for miles driven out of state or off public roads. You can choose this option as part of the simulation. A third way, tested in previous research in Washington, is to allow drivers to choose a location-enabled mileage reporting technology that can automatically deduct miles driven out of state or off public roads from the amount they owe."
        ]
    },
    {
        question: "Can miles be reported without using GPS data?",
        answer: [
            "Yes. Participants can select “Self-Reporting”. This option requires participants to report their vehicle’s odometer reading via a web-based account system. The odometer reading approach does not require any technology or GPS to utilize.",
        ]
    },
    {
        question: "Do participants have to pay with their own (real) money?",
        answer: [
            "No. Participants are not required to pay real money. However, certain participants may be eligible for follow-on research as part of this research. Those who volunteer to participate in the extended research, if selected by the research team, may receive pre-paid debit cards with sufficient funds to pay simulated road usage charge payments.",
        ]
    },
    {
        question: "How would out-of-state drivers be handled in a road usage charge system in Washington state?",
        answer: [
            "If a road usage charge system is put in place in the future, the gas tax will likely need to stay in place while the state slowly transitions away from it. During this time, drivers would only pay the road usage charge or the gas tax, but not both. As long as the gas tax stays in place, out-of-state drivers would continue to pay the gas tax at the pump, just like they do today. Once the gas tax is fully retired, decision makers will need to determine how they will collect the tax from out-of-state drivers based upon the technology and systems available at that time.",
        ]
    },
    {
        question: "Have other states adopted a road usage charge?",
        answer: [
            "Utah, Oregon, and Virginia are the three states with currently operating programs. About a dozen states are exploring and developing their own programs.",
        ]
    },
]

export const FAQ: React.FunctionComponent<{autoExpand?: boolean, sx?: SxProps<Theme>}> = (props) => {
    const appInsights = useAppInsightsContext();

    const handleAccordianChanged = (question: string, expanded: boolean) => {
        if (expanded) {
            appInsights.trackEvent({name: "ShowFAQAnswer"}, { question });
        }
    }

    return (
        <Box sx={props.sx}>
            <Box>
                <Grid container rowSpacing={2}>
                    <Grid xs={12}>
                        <Typography variant="h3">
                            Frequently Asked Questions
                        </Typography>
                        <Divider sx={{marginTop: 1}}/>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container rowSpacing={0}>
                    {qAndAs.map((item) =>
                    <Grid key={item.question} xs={12}>
                        <Divider />
                        <Accordion sx={{pageBreakInside: 'avoid'}} onChange={(event, expanded) => handleAccordianChanged(item.question, expanded)}>
                            <AccordionSummary  expandIcon={<ExpandArrow fontSize='small'/>}>
                                <Typography variant="h2">
                                    {item.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{display: 'flex', flexDirection: 'column', gridGap: '0.5rem'}}>
                                    {item.answer.map((answer) =>
                                    <Typography>
                                        <Markup htmlString={answer} htmlTag='span'/>
                                    </Typography>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    )}
                </Grid>
            </Box>
        </Box>
    );
}

FAQ.defaultProps = {
    autoExpand: false
};
