import * as React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { HeaderBodyToggleButtonGroup, PilotModal } from '../components';
import { UserContext } from '../contexts';
import { PaymentMethods, SimSteps } from '../models';
import BaseInputPage from './BaseInputPage';
import { StepWizardPageProps } from './BasePage';

export const PaymentMethodPage: React.FunctionComponent<StepWizardPageProps> = (props) => {
    const {user} = React.useContext(UserContext);
    const [pay_method, setPay_method] = React.useState(PaymentMethods.CreditCard.value);
    const [showContinueDialog, setShowContinueDialog] = React.useState(false);

    React.useEffect(() => {
        setPay_method((user && user.pay_method) ? user.pay_method : PaymentMethods.CreditCard.value);
    }, [user]);

    const handleAcceptClick = async () => {
        setShowContinueDialog(true);
        return false;
    }

    const continueDialogOnClose = async (_e: object, reason: string) => {
        setShowContinueDialog(false);
        await props.nextPage();
    };

    return (
        <BaseInputPage
            stepWizardProps={props as unknown as StepWizardChildProps}
            acceptOnClick={handleAcceptClick}
            userUpdates={{
                pay_method
            }}
            title="How would you like to pay for your road usage charge?">
            <HeaderBodyToggleButtonGroup
                value={pay_method}
                values={PaymentMethods}
                onChange={setPay_method}
            />
            <PilotModal
                open={showContinueDialog}
                onClose={continueDialogOnClose}
                abortLabel=""
                acceptLabel="Continue"
                title="We are not collecting payment or billing info in this simulation.">
            </PilotModal>
        </BaseInputPage>
    );
}

PaymentMethodPage.defaultProps = {
    stepName: SimSteps.PaymentMethod.name
};
