import { Button, Divider, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { CloseIcon } from '../icons';
import * as Themes from '../themes';
import { Colors } from '../themes/Common';

export interface BasicModalProps {
    open: boolean,
    title?: string,
    useDarkTheme?: boolean,
    acceptLabel?: string,
    abortLabel?: string,
    header?: React.ReactElement<any, any>,
    children?: React.ReactNode,
    onClose?: ((event: {}, reason: "backdropClick" | "escapeKeyDown" | "accept" | "cancel") => void)
}

export const BasicModal: React.FunctionComponent<BasicModalProps> = (props) => {
    const [theme] = React.useState(props.useDarkTheme ? Themes.Dark : Themes.Light);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const onCloseHandler = (event: object, reason: "backdropClick" | "escapeKeyDown") => {
        if (props.onClose && reason !== "backdropClick") {
            props.onClose(event, reason);
        }
    };

    const accept = () => {
        props.onClose && props.onClose({}, "accept");
    };

    const cancel = () => {
        props.onClose && props.onClose({}, "cancel");
    };

    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={props.open}
                onClose={onCloseHandler}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: !isSmall ? '50%' : 'unset',
                    left: !isSmall ? '50%' : 'unset',
                    transform: !isSmall ? 'translate(-50%, -50%)' : 'unset',
                    bottom: isSmall ? 0 : 'unset',
                    width: '28.6875rem',
                    maxWidth: '100vw',
                    bgcolor: 'background.paper',
                    borderColor: props.useDarkTheme ? Colors.gray4 : Colors.blue3,
                    borderWidth: !isSmall ? "1px" : "1px 0px 0px 0px",
                    borderRadius: !isSmall ? "4px" : 0,
                    borderStyle: "solid",
                    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.12)",
                    }}>
                    <CloseIcon sx ={{
                        position: 'absolute',
                        right: '0.8125rem',
                        top: '-1.4375rem',
                        cursor: "pointer",
                        fontSize: '0.8125rem',
                        color: props.useDarkTheme ? Colors.pilotYellow : Colors.white
                    }} onClick={cancel}/>
                    <Box sx={{padding:2}}>
                        {props.header}
                        {props.title &&
                            <Typography variant={props.useDarkTheme ? "h3" : "h2"} marginBottom='2rem'>
                                {props.title}
                            </Typography>
                        }
                        {props.children}
                    </Box>
                    {(props.acceptLabel || props.abortLabel) && <Divider/> }
                    <Box margin={0.75} marginRight={2} style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        {props.acceptLabel && <Button onClick={accept} variant="contained" sx={{marginLeft: '0.5rem'}}>{props.acceptLabel}</Button>}
                        {props.abortLabel && <Button onClick={cancel} variant="text">{props.abortLabel}</Button>}
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

BasicModal.defaultProps = {
    useDarkTheme: false,
    acceptLabel: "Yes"
}

export default BasicModal;
